import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import { CTA_NAMES, CTA } from "../components/CTAs"
import {
  FirstSection,
  FocusSection,
  BoxesSection,
  SecurityFeaturesSection,
} from "../components/Sections"

import SectionSeparator from "../components/SectionSeparator"
import SectionContainer from "../components/SectionContainer"

import { Box, Typography } from "@material-ui/core"
import { BasicContentBox } from "../components/ContentBoxes"
import TextList from "../components/TextList"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Knox" />
      <FirstSection
        image="Auvents1"
        h1="Insured Bitcoin Key Storage"
        boxHeader="Finally—"
        cta={<CTA name={CTA_NAMES.scheduleDemo} />}
      >
        <Typography variant="body1">
          Bitcoin custody that is insured to the full value of holdings for the
          risk of theft and loss, including collusion. No need to blindly trust
          Knox. We made it such that we cannot be evil and only focus on
          warehousing services.
        </Typography>
      </FirstSection>

      <SectionSeparator title="SOC 2 Type II Audit" />

      <BoxesSection
        boxes={[
          {
            content: (
              <BasicContentBox header="SOC 2 Type II Completed">
                <>
                  <Typography variant="body1">
                    Earlier in 2020, we announced the successful completion of a
                    SOC 2 Type II audit, becoming the only custodian in Canada
                    with this certification. Such attestations are essential to
                    show robust internal controls and security processes are
                    established within our firm.
                  </Typography>
                  <Box mt={4}>
                    <CTA name={CTA_NAMES.soc2} />
                  </Box>
                </>
              </BasicContentBox>
            ),
            margin: { xs: { bottom: "150px" } },
            maxWidth: "500px",
          },
        ]}
        image={{ name: "Cieling2" }}
      />

      <FocusSection
        title={
          <span>
            <span style={{ display: "block" }}>Trust.</span> — Minimized
          </span>
        }
        paragraphs={[
          "There is no such thing as ‘perfect security’. Extreme risk mitigation with technology is what we do. For unlikely edge cases, our insurance coverage protects us up to 100% of the value of customer holdings. Don’t take our word for it. We can produce certificates signed by our broker, Marsh, attesting to the insurance properties.",
        ]}
        cta={<CTA name={CTA_NAMES.custody} />}
      />

      <BoxesSection
        boxes={[
          {
            align: "left",
            maxWidth: "550px",
            margin: { xs: { bottom: "150px" } },
            content: (
              <BasicContentBox header="Security: Machine Guarantees, Not Human Policy">
                <>
                  <Typography variant="body1">
                    Collusion-resistant private key management is the nut we’ve
                    cracked after years of R&D to isolate critical risks.
                  </Typography>
                  <TextList
                    listIcon="—"
                    items={[
                      "Entirely air-gapped specialized hardware running custom policy logic",
                      "A dual-control operational model runs offline transaction processing",
                      "Geographically distinct facilities communicate in a closed network",
                    ]}
                  />
                  <Typography variant="body1">
                    Yes, that is a mouthful for most. But we allow the expert
                    eye to verify. Ultimately, Knox is explicitly insured
                    against internal and external thefts across the full key
                    lifecycle.
                  </Typography>
                  <Box mt={4}>
                    <CTA name={CTA_NAMES.security} />
                  </Box>
                </>
              </BasicContentBox>
            ),
          },
          {
            align: "right",
            margin: { xs: { bottom: "150px" } },
            maxWidth: "600px",
            content: (
              <BasicContentBox header="Insurance: the Right to be Protected">
                <>
                  <Typography variant="body1">
                    For insurance, the devil is in the details. Most policies
                    covering Bitcoin theft and loss fall short and provide a
                    false sense of security. All Knox custody accounts are
                    insurable up to the full value of holdings.
                  </Typography>
                  <Typography variant="body1">
                    On the back of our collusion-resistant technology, we have
                    gone further:
                  </Typography>
                  <TextList
                    listIcon="—"
                    items={[
                      "Insured custody with insurance for up to 100% of held value",
                      "Insurance limits are exclusively allocated to each client account",
                      "Signed certificates attesting to our insurance guarantees",
                      "Insurance protection against key loss",
                      "Insurance protection against external theft",
                      "Insurance protection against internal collusion at Knox",
                    ]}
                  />
                  <Box mt={4}>
                    <CTA name={CTA_NAMES.insurance} />
                  </Box>
                </>
              </BasicContentBox>
            ),
          },
        ]}
        image={{
          name: "Auvents2",
          position: { md: { left: "150px", top: "100px" } },
        }}
      />
      <FocusSection
        paragraphs={[
          "You can also talk to us directly to learn more about our custody product, insurance policy or get a demo to register a Knox Custody account.",
        ]}
        align="center"
        cta={<CTA name={CTA_NAMES.scheduleCall} />}
      />
    </Layout>
  )
}

export default IndexPage
